import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-autopatcher"
    }}>{`What is AutoPatcher`}</h1>
    <p><strong parentName="p">{`AutoPatcher - automated multi cloud patching solution that ensures security of operating systems in the cloud.`}</strong></p>
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`Automated patching system able to update Windows and Linux (Ubuntu, Redhat, Centos, Amazon etc.)`}</li>
      <li parentName="ul">{`Works for AWS, Azure, Google or other instances.`}</li>
      <li parentName="ul">{`Completely control the scheduling of patches in your fleet with plans that define sequencing, concurrency, success criteria and maintenance windows`}</li>
      <li parentName="ul">{`Rich notification support with e-mail, Slack and PagerDuty and granular notifications`}</li>
      <li parentName="ul">{`Automatically execute preparatory and post patching actions such as tests`}</li>
      <li parentName="ul">{`Detailed reporting shown in GUI as well as an ability to export as PDF or CSV`}</li>
      <li parentName="ul">{`Control everything from GUI or use CLI`}</li>
      <li parentName="ul">{`3rd party authentication and user roles for security`}</li>
      <li parentName="ul">{`Execute custom patching scripts for complex scenarios (such as database clusters)`}</li>
      <li parentName="ul">{`Leverages open source AWS SSM agent at the host`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      